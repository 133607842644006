import { Component, Input } from '@angular/core';

@Component({
  selector: 'logo-mark',
  templateUrl: './logo-mark.component.html',
  styleUrls: ['./logo-mark.component.scss']
})
export class LogoMarkComponent {
  @Input() bounce: boolean;
  @Input() isSpinning: boolean;
}
