import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
    selector: 'admin-login',
    templateUrl: './admin-login.component.html',
    styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnDestroy {
    componentDestroyed$: Subject<boolean> = new Subject();

    constructor(private authService: AuthService,
        private router: Router) {

    }

    loginWithGoogle(): void {
        this.authService.signInWithGoogle().then(() => this.router.navigate(['admin/reports']));
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
    }
}
