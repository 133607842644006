import { Component, Input } from '@angular/core';

@Component({
  selector: 'email-button',
  templateUrl: './email-button.component.html',
  styleUrls: ['./email-button.component.scss']
})

export class EmailButtonComponent {
  @Input() buttonText: string;
}
