import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// import { User } from 'firebase';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private userPromise: Promise<any>;
    private firebaseUser: any;
    private adminLoggedIn = false;
    // userIsViqas = false;

    constructor(private afAuth: AngularFireAuth, private functions: AngularFireFunctions) {

        this.userPromise = new Promise<any>((resolve) => {
            return this.afAuth.onAuthStateChanged(async user => {
                // this.userIsViqas = user.email === 'viqas.hussain@ag-grid.com';
                this.adminLoggedIn = await this.isUserAdmin();
                const isStaffLoggedIn = user != null && user.email != null && user.email.endsWith('@ag-grid.com')
                if (!this.adminLoggedIn && !isStaffLoggedIn) {
                    // this.adminLoggedIn = false;
                    this.afAuth.signInAnonymously();
                }
                resolve(user);
            })
        });
    }

    signIn(email, password) {
        return this.afAuth.signInWithEmailAndPassword(email, password);
    }

    signInWithGoogle() {
        return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    async isUserAdmin(): Promise<boolean> {
        const getIsUserAdmin = this.functions.httpsCallable('getIsUserAdmin');
        const response = await getIsUserAdmin({}).toPromise();
        return response;
    }

    async firebaseLogin() {
        this.firebaseUser = await this.userPromise;
    }

    userIsLoggedInAnonymously(): boolean {
        return this.firebaseUser !== undefined;
    };

    isAdminLoggedIn(): Promise<boolean> {
        return this.userPromise.then(x => this.adminLoggedIn);
    }

    isUserSuperAdmin(): Promise<boolean> {
        return this.userPromise.then(x => x.email === 'viqas.hussain@ag-grid.com' || x.email === 'sachshell.rhoden@ag-grid.com' || x.email === 'simon.kenny@ag-grid.com' || x.email === 'dimple.unalkat@ag-grid.com');
    }

    logout() {
        return this.afAuth.signOut();
    }
}
