import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FirebaseLoggerService {

    constructor(private functions: AngularFireFunctions) {
    }

    public logCriticalErrorToFirebase(message: string) {
        try {
            const logger = this.functions.httpsCallable('logCriticalError');
            logger({message}).pipe(take(1)).subscribe();
        } catch (e) {
            // ignore
        }
    }

    public logErrorToFirebase(message: string) {
        try {
            const logger = this.functions.httpsCallable('logError');
            logger({message}).pipe(take(1)).subscribe();
        } catch (e) {
            // ignore
        }
    }

    public logInfoToFirebase(message: string) {
        try {
            const logger = this.functions.httpsCallable('logInfo');
            logger({message}).pipe(take(1)).subscribe();
        } catch (e) {
            // ignore
        }
    }
}
