<div class="container">
  <ng-container *ngTemplateOutlet="logoType"></ng-container>
  <logo-mark></logo-mark>
</div>

<ng-template #logoType>
  <svg xmlns="http://www.w3.org/2000/svg" class="logotype" width="154" height="40" viewBox="0 0 154 40">
    <style>
      .logotype path, .logotype rect { fill: #fff; }
      .logotype.dark-type > path, .logotype.dark-type > rect { fill: #003264; }
    </style>
  
    <path d="M147.979,5.6l-0,28.417l-4.768,-0l-0.127,-2.988c-0.7,1.017 -1.59,1.844 -2.67,2.479c-1.081,0.572 -2.416,0.89 -3.942,0.89c-1.335,0 -2.606,-0.254 -3.687,-0.699c-1.144,-0.509 -2.162,-1.144 -2.988,-2.034c-0.826,-0.89 -1.526,-1.971 -1.971,-3.179c-0.508,-1.208 -0.699,-2.606 -0.699,-4.132c-0,-1.526 0.254,-2.924 0.699,-4.196c0.509,-1.271 1.145,-2.352 1.971,-3.242c0.826,-0.89 1.844,-1.589 2.988,-2.098c1.144,-0.509 2.352,-0.763 3.687,-0.763c1.526,0 2.797,0.254 3.878,0.827c1.081,0.572 1.971,1.335 2.67,2.415l-0,-11.633l4.959,-0l-0,-0.064Zm-10.426,24.539c1.653,-0 2.924,-0.572 3.941,-1.653c1.017,-1.081 1.526,-2.543 1.526,-4.323c-0,-1.78 -0.509,-3.179 -1.526,-4.323c-1.017,-1.081 -2.288,-1.653 -3.941,-1.653c-1.59,0 -2.925,0.572 -3.878,1.653c-1.017,1.081 -1.526,2.543 -1.526,4.323c0,1.78 0.509,3.179 1.526,4.259c1.017,1.145 2.288,1.717 3.878,1.717"/>
    <rect x="119.067" y="5.6" width="4.959" height="5.849"/>
    <rect x="119.067" y="14.373" width="4.959" height="19.644"/>
    <path d="M108.831,16.979c1.59,-2.352 5.595,-2.606 7.248,-2.606l-0,4.577c-2.035,0 -4.069,0.064 -5.277,0.954c-1.208,0.89 -1.843,2.098 -1.843,3.56l-0,10.553l-4.959,-0l0,-19.644l4.768,-0l0.063,2.606Z"/>
    <path d="M26.489,28.677l-12.46,-0l-2.162,5.34l-5.467,-0l11.507,-26.955l4.768,0l11.506,26.955l-5.531,-0l-2.161,-5.34Zm-1.717,-4.26l-4.513,-10.998l-4.514,10.998l9.027,0Z"/>
    <path d="M62.024,18.654l-12.286,0l-0,4.267l6.963,0c-0.219,2.139 -1.021,3.846 -2.406,5.123c-1.385,1.276 -3.208,1.914 -5.469,1.914c-1.288,0 -2.461,-0.231 -3.518,-0.693c-1.058,-0.462 -1.964,-1.113 -2.717,-1.95c-0.753,-0.839 -1.337,-1.848 -1.749,-3.027c-0.414,-1.179 -0.62,-2.486 -0.62,-3.92c-0,-1.433 0.206,-2.739 0.62,-3.919c0.412,-1.178 0.996,-2.187 1.749,-3.026c0.753,-0.838 1.665,-1.488 2.734,-1.95c1.07,-0.462 2.248,-0.693 3.538,-0.693c2.669,-0 4.696,0.642 6.081,1.927l3.284,-3.286c-2.486,-1.934 -5.63,-2.907 -9.439,-2.907c-2.114,-0 -4.023,0.334 -5.723,1.002c-1.703,0.669 -3.161,1.605 -4.376,2.807c-1.216,1.204 -2.151,2.657 -2.807,4.357c-0.657,1.702 -0.985,3.599 -0.985,5.688c-0,2.066 0.334,3.957 1.003,5.67c0.668,1.714 1.61,3.173 2.825,4.375c1.216,1.204 2.673,2.139 4.375,2.808c1.702,0.668 3.597,1.002 5.688,1.002c2.042,0 3.883,-0.334 5.524,-1.002c1.64,-0.669 3.038,-1.604 4.193,-2.808c1.154,-1.202 2.042,-2.661 2.661,-4.375c0.621,-1.713 0.93,-3.604 0.93,-5.67c0,-0.291 -0.007,-0.577 -0.017,-0.856c-0.014,-0.28 -0.032,-0.565 -0.056,-0.858"/>
    <path d="M100.792,18.654l-12.286,0l-0,4.267l6.963,0c-0.219,2.139 -1.021,3.846 -2.406,5.123c-1.385,1.276 -3.208,1.914 -5.469,1.914c-1.288,0 -2.461,-0.231 -3.518,-0.693c-1.058,-0.462 -1.964,-1.113 -2.717,-1.95c-0.753,-0.839 -1.337,-1.848 -1.749,-3.027c-0.414,-1.179 -0.62,-2.486 -0.62,-3.92c-0,-1.433 0.206,-2.739 0.62,-3.919c0.412,-1.178 0.996,-2.187 1.749,-3.026c0.753,-0.838 1.665,-1.488 2.735,-1.95c1.069,-0.462 2.247,-0.693 3.537,-0.693c2.669,-0 4.697,0.642 6.081,1.927l3.284,-3.286c-2.486,-1.934 -5.63,-2.907 -9.438,-2.907c-2.115,-0 -4.024,0.334 -5.724,1.002c-1.703,0.669 -3.161,1.605 -4.376,2.807c-1.216,1.204 -2.151,2.657 -2.807,4.357c-0.657,1.702 -0.985,3.599 -0.985,5.688c-0,2.066 0.334,3.957 1.003,5.67c0.668,1.714 1.61,3.173 2.825,4.375c1.216,1.204 2.673,2.139 4.375,2.808c1.702,0.668 3.597,1.002 5.689,1.002c2.041,0 3.883,-0.334 5.523,-1.002c1.64,-0.669 3.038,-1.604 4.193,-2.808c1.154,-1.202 2.042,-2.661 2.661,-4.375c0.621,-1.713 0.93,-3.604 0.93,-5.67c0,-0.291 -0.007,-0.577 -0.017,-0.856c-0.014,-0.28 -0.032,-0.565 -0.056,-0.858"/>
  </svg>
</ng-template>