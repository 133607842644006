import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {
    private showLoadingSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);

    setShowLoadingSpinner(showLoadingSpinner: boolean) {
        this.showLoadingSpinner.next(showLoadingSpinner);
    }

    getShowLoadingSpinner() {
        return this.showLoadingSpinner.asObservable();
    }
}
