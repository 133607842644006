import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputFieldComponent } from './text-input-field/text-input-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IeMessageComponent } from './ie-message/ie-message.component';
import { LogoComponent } from './logo/logo.component';
import { LogoMarkComponent } from './logo/logo-mark.component';
import { EmailButtonComponent } from './email-button/email-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { SomethingWentWrongDialog } from './something-went-wrong-dialog.component';
import { FirewallDialogComponent } from './firewall-dialog-component';
import { NoticeMessage } from './notice-message/notice-message.component';

@NgModule({
  declarations: [TextInputFieldComponent, IeMessageComponent, LogoComponent, LogoMarkComponent, NoticeMessage, EmailButtonComponent, SomethingWentWrongDialog, FirewallDialogComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule
  ],
  entryComponents: [
    SomethingWentWrongDialog, FirewallDialogComponent
  ],
  exports: [TextInputFieldComponent, IeMessageComponent, LogoComponent, LogoMarkComponent, NoticeMessage, EmailButtonComponent, SomethingWentWrongDialog, FirewallDialogComponent]
})
export class SharedModule { }
