import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FirebaseConnectivityService {

  constructor(private functions: AngularFireFunctions,
    private http: HttpClient) { }

  testConnection(): Observable<string> {
    return this.functions.httpsCallable('connectionTest')(null);
  }

  getIpAddress(): Observable<any> {
    return this.http.get('https://api.ipify.org/?format=json')
  }
}
