<div class="input-field {{inputFieldClass}}"
    [ngClass]="{ 'input-error': inputFormControl.invalid && (inputFormControl.dirty || inputFormControl.touched) }">
    <label [for]="name">{{label}}<span *ngIf="required" class="req">*</span></label>
    <input
        type="text"
        [id]="name"
        [name]="name"
        [placeholder]="placeholder"
        [formControl]="inputFormControl"
        [required]="required"
        [readonly]="readonly"
        (change)="inputChanged()"
    />
    <div class="error" *ngIf="inputFormControl.errors">
        <ng-content></ng-content>
    </div>
</div>