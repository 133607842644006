import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const redirectUrl = route['_routerState']['url'];

        return this.authService.isAdminLoggedIn().then(isStaffLoggedIn => {
            if (isStaffLoggedIn) {
                return true;
            }

            this.router.navigateByUrl(
                this.router.createUrlTree(
                    ['/adminLogin'], {
                    queryParams: {
                        redirectUrl
                    }
                })
            );
            return false;
        })
    }
}
