import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ie-message',
  templateUrl: './ie-message.component.html',
  styleUrls: ['./ie-message.component.scss']
})
export class IeMessageComponent implements OnInit {

  constructor() { }

  isIe = !!(window.document as any).documentMode;

  ngOnInit() {
  }

}
