import { ErrorHandler, Injectable } from '@angular/core';
import { FirebaseLoggerService } from './firebase-logger.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
  constructor(private firebaseLoggerService: FirebaseLoggerService) {
    super();
  }

  handleError(error: any) {
    // this.firebaseLoggerService.logErrorToFirebase(`Error in console: ${error.stack}`);
    super.handleError(error);
  }
}