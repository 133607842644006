import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
// material design
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// angular fire
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminGuard } from './guards/admin.guard';
import { AppRoutingModule } from './app-routing.module';
import { CustomErrorHandlerService } from './services/custom-error-handler.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material';
import { SharedModule } from './shared/shared.module';


if (environment.production) {
    console.warn('*******************************************************');
    console.warn('*********** Running in LIVE/PRODUCTION mode ***********');
    console.warn('*******************************************************');
}

// for use when debugging firebase functions locally
const dynamicProviders = [];
if ((environment as any).emulateFirebase) {
    dynamicProviders.push({
        provide: null,
        // provide: FUNCTIONS_ORIGIN,
        useValue: (environment as any).firebaseFunctionsUrl
    }
    );
}

declare var window;

const firebaseConfig = environment.firebaseConfig;

@NgModule({
    declarations: [
        AppComponent,
        AdminLoginComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(firebaseConfig),
        MatProgressSpinnerModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        MatFormFieldModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        SharedModule
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: window['base-href']
        },
        { provide: ErrorHandler, useClass: CustomErrorHandlerService },
        AdminGuard,
        ...dynamicProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
