<svg xmlns="http://www.w3.org/2000/svg" class="logo-mark" 
  [ngClass]="{ 'bounce': bounce, 'loading': isSpinning }"
  width="64" height="48" viewBox="0 0 64 48">
  <style>
    .logo-mark .aqua { fill: #55b4c8; }
    .logo-mark .orange { fill: #ff8c00; }
    .logo-mark .red { fill: #f00; }
    .logo-mark .grey { fill: #b4bebe; }
  </style>
  
  <rect class="aqua right-1" x="51" y="10" width="7" height="8"/>
  <path class="aqua right-1" d="M58,10l-17,0l-8,8l25,0l0,-8Z"/>
  <rect class="orange right-2" x="36" y="22" width="7" height="8"/>
  <path class="orange right-2" d="M43,30l0,-7.995l-14,-0l-8.008,7.995l22.008,0Z"/>
  <rect class="red right-3" x="24" y="34" width="7" height="8"/>
  <path class="red right-3" d="M13,38.01l4,-4.01l14,0l0,8l-18,0l0,-3.99Z"/>

  <rect class="grey left-1" x="11" y="6" width="7" height="8"/>
  <path class="grey left-1" d="M41,10l-4,4l-26,0l0,-8l30,0l0,4Z"/>
  <rect class="grey left-2" x="16" y="18" width="7" height="8"/>
  <path class="grey left-2" d="M16,26l9,0l8,-8l-17,-0l0,8Z"/>
  <rect class="grey left-3" x="6" y="30" width="7" height="8"/>
  <path class="grey left-3" d="M6,37.988l7,0.012l7.992,-8l-14.992,-0.047l-0,8.035Z"/>
</svg>