import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'text-input-field',
  templateUrl: './text-input-field.component.html',
  styleUrls: ['./text-input-field.component.scss']
})
export class TextInputFieldComponent {
  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() inputFormControl: FormControl;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() inputFieldClass: string;

  @Output() inputChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  formGroup: FormGroup;

  inputChanged() {
    this.inputChangedEvent.emit();
  }
}
