import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingSpinnerService } from './services/loading-spinner.service';
import { AuthService } from './services/auth.service';
import { FirebaseLoggerService } from './services/firebase-logger.service';
import { FirebaseConnectivityService } from './services/firebase-connectivity.service';
import { timeout, take, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FirewallDialogComponent } from './shared/firewall-dialog-component';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    showLoadingSpinner = false;

    constructor(private loadingSpinnerService: LoadingSpinnerService,
        private authService: AuthService,
        public firebaseLoggerService: FirebaseLoggerService,
        private firebaseConnectivityService: FirebaseConnectivityService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.authService.firebaseLogin();
        this.loadingSpinnerService.getShowLoadingSpinner().subscribe(showLoadingSpinner => this.showLoadingSpinner = showLoadingSpinner);

        // this.firebaseConnectivityService.testConnection().pipe(
        //     take(1),
        //     timeout(30000), 
        //     catchError(x => {

        //         // const firewallDialog = this.dialog.open(FirewallDialogComponent, {
        //         //     height: '300px',
        //         //     width: '450px',
        //         // });

        //         // this.firebaseLoggerService.logCriticalErrorToFirebase(`Firebase firewall connection test failed for user, error: ${JSON.stringify(x)}`);

        //         // this.firebaseConnectivityService.getIpAddress().subscribe(
        //         //     x => this.firebaseConnectivityService.sendEmailToViqas(`Firebase firewall connection test failed for user with IP: ${x.ip}`));

        //         return of('Connection Test Timeout!')
        //     }),
        // ).subscribe();
    }
}