export default {
    firebaseConfig: {
        apiKey: 'AIzaSyC94Sa-40C5bhDVWIr39Z_KDttzWWpPJD8',
        authDomain: 'stripe-testing-19784.firebaseapp.com',
        databaseURL: 'https://stripe-testing-19784.firebaseio.com',
        projectId: 'stripe-testing-19784',
        storageBucket: 'stripe-testing-19784.appspot.com',
        messagingSenderId: '431418318855',
        appId: '1:431418318855:web:f3672aa79b958f26'
    },
    globalPaymentsServiceUrl: "https://pay.sandbox.realexpayments.com/pay",
    // test key - only use on localhost
    // captchaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    captchaSiteKey: "6LdPAJAgAAAAAFZK_1oY2E5f-TIVdP02aDiQ5wvF",
    siteDomain: "https://ecommerce.ag-grid.com/#/"
    // siteDomain: "http://localhost:4200/#/"
};

